<!--
 * @Author: ckz 1498632919@qq.com
 * @Date: 2024-08-08 11:10:45
 * @LastEditors: ckz 1498632919@qq.com
 * @LastEditTime: 2024-08-10 17:27:49
 * @FilePath: \ckz\js_decoration\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
  <div class="app">
    <router-view></router-view>

  </div>
</template>

<script >
</script>



<style lang="less">
	@import './assets/css/inital.css';
    .app{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
</style>
