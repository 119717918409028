/*
 * @Author: ckz 1498632919@qq.com
 * @Date: 2024-08-08 11:10:45
 * @LastEditors: ckz 1498632919@qq.com
 * @LastEditTime: 2024-09-03 11:11:42
 * @FilePath: \ckz\js_decoration\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";


import axios from 'axios';
import VueAxios from 'vue-axios'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
// import { setupPermission } from "@/plugins";
import '@/assets/css/theme.scss'

const debounce = (fn, delay) => {
    let timer
     return (...args) => {
       if (timer) {
         clearTimeout(timer)
       }
       timer = setTimeout(() => {
         fn(...args)
       }, delay)
     }
  }
    
  const _ResizeObserver = window.ResizeObserver;
  window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
     constructor(callback) {
       callback = debounce(callback, 10);
       super(callback);
     }
  }


const app = createApp(App)
app.use(store)
app.use(router)
app.use(ElementPlus,{
    locale: zhCn,
})
// app.use(setupPermission)
app.use(VueAxios, axios)
app.mount("#app");
