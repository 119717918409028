
import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';
export default createStore({
  state: {
    visible:false,
    bright:true,
    userName:'鹰瞳工程管理系统', //用户名字
    header_Img:'https://ytskkj.com/vendor/dcat-admin/images/logo.png',//用户头像
    refresh: false, // 刷新页面
    themeConfig: {
      primary: 'DEFAULT_PRIMARY',
      isDark: false,
    },
    user:{},
    userRouter:[]


  },
  getters: {
  },
  mutations: {
    saveUserRouter(state ,list){
      state.userRouter = list
      console.log(state.userRouter,'state.userRouter')
    }
  },
  actions: {
    
  },
  persist: true,
  modules: {

  },
  plugins:[
    createPersistedState({
      key:'vuexMsg',// 存数据的key名   自定义的  要有语义化
      // paths: ['loginModule'] // 要把那些模块加入缓存
    }) 
  ]
})
